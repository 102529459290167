import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IError } from '@shared/model/api-models';
import { AlertService } from '@shared/services/alert.service';
import { StorageService } from 'src/app/pages/auth/services/storage.service';

@Injectable({
  providedIn: 'root'
})
export class ErrorHandlingService {

  constructor(
    private alertService: AlertService,
    private storageService: StorageService,
  ) { }

  handleError(error: HttpErrorResponse): void {
    let errorMessage = '';

    if (error.error instanceof ErrorEvent) {
      // client error
      errorMessage = `Client-side error: ${error.error.message}`;
    } else {
      // server error
      const serverErrorMessage = (error.error as IError).messages?.[0].description;

      switch (error.status) {
        case 400:
          // bad request
          errorMessage = `${serverErrorMessage || error.message}`;
          break;

        case 401:
          errorMessage = `Unauthorized: ${serverErrorMessage || error.message}`;
          this.storageService.logout();
          break;

        case 403:
          errorMessage = `Forbidden: ${serverErrorMessage || error.message}`;
          break;

        case 404:
          errorMessage = `Not Found: ${serverErrorMessage || error.message}`;
          break;

        case 500:
          errorMessage = `Internal Server Error: ${serverErrorMessage || error.message}`;
          break;

        default:
          errorMessage = `Unexpected Error: ${serverErrorMessage || error.message}`;
          break;
      }
    }
    this.alertService.serverErrorAlert(errorMessage);
  }
}
