import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GOING_THROUGH_ERROR_INTERCEPTOR_OPTION } from '@core/interceptors/error.interceptor';
import { IResponse } from '@shared/model/api-models';
import { catchError, map, of, tap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { IDataLogin, IParamForgetPassword, IParamLogin, IParamLogin2fa, IParamRegister, IParamRegisterCode, IParamRegisterVerify, IParamResetPassword, IResponseRegister, IResponseRegisterCode, IResponseRegisterVerify } from '../models/models';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root'
})
export class AuthApiService {

  private baseUrl = environment.apiEndpoint;

  constructor(
    private http: HttpClient,
    private storageService: StorageService,
  ) { }

  postLogin(params: IParamLogin) {
    const url = this.baseUrl + '/accounts/login';

    return this.http.post<IResponse<IDataLogin>>(url, { data: params }).pipe(
      map(response => response.data),
      catchError(() => of<false>(false)),
      tap(response => { this.storageService.login(response); })
    );
  }

  postLogin2fa(params: IParamLogin2fa) {
    const url = this.baseUrl + '/accounts/login-2fa';

    return this.http.post<IResponse<IDataLogin>>(url, { data: params }).pipe(
      map(response => response.data),
      catchError(() => of<false>(false)),
      tap(response => { this.storageService.login(response); })
    );
  }

  postForgetPassword(params: IParamForgetPassword) {
    const url = this.baseUrl + '/accounts/forget-password';

    return this.http.post<IResponse<string>>(url, { data: params }).pipe(
      catchError(() => of<false>(false)),
    );
  }

  postResetPassword(params: IParamResetPassword) {
    const url = this.baseUrl + '/accounts/reset-password';

    return this.http.post<IResponse<string>>(url, { data: params }).pipe(
      catchError(() => of<false>(false)),
    );
  }

  getAuthorization() {
    const url = this.baseUrl + '/accounts/current-user-id';

    const context = new HttpContext();
    context.set(GOING_THROUGH_ERROR_INTERCEPTOR_OPTION, {
      notAllowed: true
    });

    return this.http.get<IResponse<number>>(url, { context }).pipe(
      map(response => !response.data || true),
      catchError(() => of<false>(false)),
    );
  }

  postRegisterCode(params: IParamRegisterCode) {
    const url = this.baseUrl + '/accounts/register/code';

    return this.http.post<IResponse<IResponseRegisterCode>>(url, { data: params }).pipe(
      map(response => response.data),
      catchError(() => of<false>(false)),
    );
  }

  postRegisterVerify(params: IParamRegisterVerify) {
    const url = this.baseUrl + '/accounts/register/verify';

    return this.http.post<IResponse<IResponseRegisterVerify>>(url, { data: params }).pipe(
      map(response => response.data),
      catchError(() => of<false>(false)),
    );
  }

  postRegister(params: IParamRegister) {
    const url = this.baseUrl + '/accounts/register';

    return this.http.post<IResponse<IResponseRegister>>(url, { data: params }).pipe(
      map(response => response.data),
      catchError(() => of<false>(false)),
      tap(response => { this.storageService.login(response); })
    );
  }

}