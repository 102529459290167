import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

const LOCAL_STORAGE_NAME = 'ma';

export enum AuthStatus {
  pending = 1,
  reject = 2,
  passes = 3,
}

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  private _token: string | null = this.getMainToken();

  get token(): string | null {
    return this._token;
  }

  private _authenticationStatus: AuthStatus = AuthStatus.pending;

  get authenticationStatus(): AuthStatus {
    return this._authenticationStatus;
  }

  set authenticationStatus(value: AuthStatus) {
    this._authenticationStatus = value;
  }

  constructor(
    private router: Router,
  ) { }

  setItem(key: string, value: string) {
    localStorage.setItem(key, value);
  }

  getItem(key: string): string | null {
    const data = localStorage.getItem(key) || '';
    return data;
  }

  removeItem(key: string) {
    localStorage.removeItem(key);
  }

  length(): number {
    return localStorage.length;
  }

  clear() {
    localStorage.clear();
  }

  getMainToken(): string | null {
    const data = localStorage.getItem(LOCAL_STORAGE_NAME) || '';
    return data;
  }

  setMainToken(value: string) {
    localStorage.setItem(LOCAL_STORAGE_NAME, value);
  }

  removeMainToken() {
    localStorage.removeItem(LOCAL_STORAGE_NAME);
  }

  logout(redirection = true) {
    this._token = null;
    this.authenticationStatus = AuthStatus.reject;
    this.removeMainToken();
    if (redirection) {
      this.router.navigateByUrl('/auth/login');
    }
  }

  login(response: false | { accessToken?: string; }, redirection = true) {
    if (response && response.accessToken) {
      this._token = response.accessToken;
      this.authenticationStatus = AuthStatus.passes;
      this.setMainToken(response.accessToken);
      if (redirection) {
        this.router.navigateByUrl('/dashboard');
      }
    } else {
      this.logout();
    }
  }

}